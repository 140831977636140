angular.module('LeasePilot').directive('deleteFormModal', [
  '$http',
  function($http) {
    return {
      restrict: 'E',
      templateUrl: 'delete_form_modal.html',
      link: function($scope, element, attrs) {
        $scope.form = {
          name: attrs.name,
          deal_type: attrs.dealType,
          flavor: attrs.flavor,
          company_id: attrs.companyId,
        };

        $scope.disabled = true;

        $scope.textChanged = function() {
          if (
            _.snakeCase($scope.flavorText) === _.snakeCase($scope.form.flavor)
          ) {
            $scope.disabled = false;
          } else {
            $scope.disabled = true;
          }
        };

        $scope.delete = function() {
          var data = $.param({ form: $scope.form });

          $http.delete('/api/forms?' + data).success(function() {
            window.location.reload();
          });
        };
      },
    };
  },
]);
